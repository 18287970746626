import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { config, useSpring } from 'react-spring'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'
import { ChildImageSharp } from '../types'

type PageProps = {
  data: {
    aboutMe: ChildImageSharp
  }
}

const Headshot = styled(Img)`
  max-width: 625px;
  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    max-width: 100%;
  }
`

const Bio = styled.p`
  width: 625px;
  @media (max-width: ${props => props.theme.breakpoints[2]}) {
    width: 100%;
  }
`

const About: React.FunctionComponent<PageProps> = ({ data: { aboutMe } }) => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Layout>
      <SEO title="About | Evgenia Rayme" desc="Evgenia Rayme is an actress from New York, NY." />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]}>
        <h1>About me</h1>
        <Headshot fluid={aboutMe.childImageSharp.fluid} />
        <Bio>
        Evgenia Rayme is a Russian-Swedish actress and filmmaker based in New York, NY. She studied abroad, where she received her B.A. Degree from Moscow Art Theatre (MAT).
        </Bio>
        <Bio>
        Spanning actoss various mediums, her career includes roles, such as a series lead in Amazon's "The Damp Squid", along with guest star appearances on shows like "True Crime Re-Enactment" on ID Network and "People Magazine Investigates" on Discovery Network. She is also known for independent and short films, where she holds both strong lead and supporting roles. 
        </Bio>
        <Bio>
        Beyond acting, she is known for being an assistant director in varies TV-series, shorts and independent projects, including the popular Paramount's "Mayor of Kingstown" and Amazon's "American Rust".
        </Bio>
        <Bio>
         Contact:{' '}
          <a href="mailto:evgenia.work@gmail.com" target="_blank" rel="noopener noreferrer">
           evgenia.work@gmail.com
          </a>
        </Bio>
      </AnimatedBox>
    </Layout>
  )
}

export default About

export const query = graphql`
  query About {
    aboutMe: file(sourceInstanceName: { eq: "images" }, name: { eq: "about-us" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
